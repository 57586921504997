<template>
  <div class="">
    <div class="m-3 d-flex justify-content-between">
      <a href="#" @click="onBackClicked">Back</a>
      <div v-show="user != null && user.type != null && user.type.name == 'coordinator' && match.allow_edit">
        <a href="#" @click="onAddEventClicked" v-show="!match.is_finished">Add Match Event</a>
        <a href="#" @click="onSetFinishClicked" class="ml-3" v-show="!match.is_finished">Set Finish</a>
        <a href="#" @click="onPlayerAttendanceClicked" class="ml-3" v-show="type == 'match_event'">Player Attendance</a>
        <a href="#" @click="onMatchEventClicked" class="ml-3" v-show="type == 'player_attendance'">Match Event</a>
      </div>
    </div>

    <div class="my-5 pb-5 container " v-if="match.id != null">
      <div class="py-3">
        <div>
          <h4>{{ $t(type) }}</h4>
        </div>

        <div class="match-grid mt-4">
          <div class="match-grid-1 d-flex align-items-start">
            <div>
              <p class="m-0 font-weight-bold">{{ match.name }}</p>
              <p class="m-0 text-secondary" v-if="match.tournament != null || match.group != null">
								{{
									match.tournament != null ? match.tournament.event_category_sport_category.name : match.group.event_category_sport_category.name
								}} / {{
									match.tournament != null ? (match.tournament.type != null ? match.tournament.type.name : '-') : match.group.name
								}}</p>
							<p class="m-0 text-secondary" v-else-if="match.cutoff_member1 != null">{{ match.cutoff_member1.event_category_sport_category.name }}</p>
            </div>
          </div>
          <div class="match-grid-2 d-flex flex-column align-items-center">
            <p class="m-0 font-weight-bold">{{ match.date.format('DD/MM/YYYY') }}</p>
            <p class="m-0 text-secondary">{{ match.date.format('HH:mm') }}</p>
          </div>
          <div class="match-grid-3 text-right">
            <p class="m-0 font-weight-bold">{{ match.event_category_sport_venue != null ? match.event_category_sport_venue.venue.name : 'No Venue' }}</p>
            <p class="m-0 text-secondary">{{ match.event_category_sport_venue != null ? match.event_category_sport_venue.court : 'No Court' }}</p>
          </div>
				</div>

				<div>
					<div v-if="match.cutoff_group == null" class="d-flex align-items-center justify-content-between position-relative">
						<div class="position-absolute bg-primary w-100 h-100" style="left: 0; z-index: -1"></div>
						<div
							class="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row member-container my-1">
							<div class="d-flex align-items-center justify-content-center match-image-container">
								<img :src="match.registration_event1.url_image" />
							</div>
							<div class="text-center text-md-left mt-2 mt-md-0 ml-md-3">
								<div class="d-flex align-items-center">
									<p class="m-0 font-weight-bold text-white">{{ match.registration_event1.user.name }}</p>
									<span class="badge badge-danger ml-1 mt-1" v-show="match.group_member1_disqualified == 1">Disqualified</span>
								</div>
								<p class="m-0 text-white">{{ match.registration_event1.team_name }}</p>
								<p class="m-0 text-white">{{ match.registration_event1.jersey_team }}</p>
							</div>
						</div>

						<div class="d-flex align-items-center justify-content-center score-container">
							<button class="btn btn-danger mr-1" v-show="match.status != 'finished' && match.group_member1_disqualified == 0" @click="setDisqualified('registration_event1')">Set Disqualified</button>
							<div style="width: 8.5rem" v-show="match.status == 'finished' || match.group_member1_disqualified == 1"></div>

							<div>
								<p class="font-weight-bold text-white m-0" v-if="match.event_category_sport_category != null && match.group_member1_score_timer != null">
									{{
										(match.group_member1_score_timer.hours() < 10 ? '0' + match.group_member1_score_timer.hours() : match.group_member1_score_timer.hours()) + ':' +
										(match.group_member1_score_timer.minutes() < 10 ? '0' + match.group_member1_score_timer.minutes() : match.group_member1_score_timer.minutes()) + ':' +
										(match.group_member1_score_timer.seconds() < 10 ? '0' + match.group_member1_score_timer.seconds() : match.group_member1_score_timer.seconds())
									}}
								</p>
								<h4 class="font-weight-bold text-white m-0" v-else>{{ match.group_member1_score }}</h4>
							</div>
							<h4 class="mx-3 text-white m-0">-</h4>
							<div>
								<p class="font-weight-bold text-white m-0" v-if="match.event_category_sport_category != null && match.group_member2_score_timer != null">
									{{
										(match.group_member2_score_timer.hours() < 10 ? '0' + match.group_member2_score_timer.hours() : match.group_member2_score_timer.hours()) + ':' +
										(match.group_member2_score_timer.minutes() < 10 ? '0' + match.group_member2_score_timer.minutes() : match.group_member2_score_timer.minutes()) + ':' +
										(match.group_member2_score_timer.seconds() < 10 ? '0' + match.group_member2_score_timer.seconds() : match.group_member2_score_timer.seconds())
									}}
								</p>
								<h4 class="font-weight-bold text-white m-0" v-else>{{ match.group_member2_score }}</h4>
							</div>

							<button class="btn btn-danger ml-1" v-show="match.status != 'finished' && match.group_member2_disqualified == 0" @click="setDisqualified('registration_event2')">Set Disqualified</button>
							<div style="width: 8.5rem" v-show="match.status == 'finished' || match.group_member2_disqualified == 1"></div>
						</div>

						<div
							class="d-flex align-items-center justify-content-end flex-column-reverse flex-md-row member-container my-1">
							<div class="text-center text-md-right mt-2 mt-md-0 mr-md-3">
								<div class="d-flex justify-content-end align-items-center">
									<span class="badge badge-danger mr-1 mt-1" v-show="match.group_member2_disqualified == 1">Disqualified</span>
									<p class="m-0 font-weight-bold text-white">{{ match.registration_event2.user.name }}</p>

								</div>
								<p class="m-0 text-white">{{ match.registration_event2.team_name }}</p>
								<p class="m-0 text-white">{{ match.registration_event2.jersey_team }}</p>
							</div>
							<div class="d-flex align-items-center justify-content-center match-image-container">
								<img :src="match.registration_event2.url_image" />
							</div>
						</div>


					</div>

        </div>

        <div>
          <div v-if="user != null && user.type != null && user.type.name == 'coordinator'">
						<div v-show="type == 'match_event'">
							<MatchEventCutoff :arr_match_event="arr_match_event" :match="match" :user="user" :rnd_time="rnd_time"
								v-if="match.cutoff_group != null" @onEventClicked="onEventCutoffClicked" />
							<MatchEvent :arr_match_event="arr_match_event" :match="match" :user="user" :rnd_time="rnd_time"
							v-else @onRemoveEventClicked="onRemoveEventClicked"
							@onEventClicked="onEventClicked" />
						</div>

						<div v-show="type == 'player_attendance'">
							<PlayerAttendanceCutoff :rnd_time="rnd_time" :user="user" :match="match" v-if="match.cutoff_group != null" />
							<PlayerAttendance :arr_player1="arr_member1_player" :arr_player2="arr_member2_player" :rnd_time="rnd_time"
								:user="user" :match="match" v-else />
						</div>
          </div>
          <div v-else>
            <MatchEventUser :arr_match_event="arr_match_event" :match="match" :user="user"
              v-show="type == 'match_event_user'" />
          </div>
        </div>

        <div class="breakline"></div>

        <div class="mt-3">
          <div class="d-flex justify-content-between">
            <p class="m-0">Best Player</p>
            <p class="m-0">{{ match.best_player != null ? match.best_player.name : '-' }}</p>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Competition Coordinator</p>
            <p class="m-0">{{ match.str_coordinator }}</p>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Referee</p>
            <p class="m-0">{{ match.referee != null ? match.referee : '-' }}</p>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Judge 1</p>
            <p class="m-0">{{ match.judge1 != null ? match.judge1 : '-' }}</p>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Judge 2</p>
            <p class="m-0">{{ match.judge2 != null ? match.judge2 : '-' }}</p>
          </div>
        </div>

        <div class="breakline"></div>
      </div>
    </div>

    <div v-if="user != null && user.type != null && user.type.name == 'coordinator'">
			<AddMatchEventCutoff :event_data="selected_event" :event_category_sport="match_data.tournament.event_category_sport" @onForceReload="onForceReload" :match_data="match" :arr_group_member="arr_group_member" v-if="match_data.tournament != null && match_data.tournament.event_category_sport.scoring_type.data == 'cutoff_tournament'"/>
      <AddMatchEvent :event_data="selected_event" :match_data="match" @onForceReload="onForceReload" v-else/>

      <AddBestPlayer :match_data="match" @onForceReload="onForceReload" @onMatchFinished="onMatchFinished"/>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'
import AddMatchEvent from '@/pages/competition/add_match_event.vue'
import AddMatchEventCutoff from '@/pages/competition/add_match_event_cutoff.vue'
import AddBestPlayer from '@/pages/competition/add_best_player.vue'

import MatchEvent from '@/pages/competition/component/match_event.vue'
import MatchEventCutoff from '@/pages/competition/component/match_event_cutoff.vue'
import MatchEventUser from '@/pages/competition/component/match_event_user.vue'
import PlayerAttendance from '@/pages/competition/component/player_attendance.vue'
import PlayerAttendanceCutoff from '@/pages/competition/component/player_attendance_cutoff.vue'

import NoImage from '@/assets/no_image.png'

export default {
  props: ['match_data', 'user_data',],
  components: {
    'AddMatchEvent': AddMatchEvent,
		'AddMatchEventCutoff': AddMatchEventCutoff,
    'AddBestPlayer': AddBestPlayer,
    'MatchEvent': MatchEvent,
		'MatchEventCutoff': MatchEventCutoff,
    'MatchEventUser': MatchEventUser,
    'PlayerAttendance': PlayerAttendance,
		'PlayerAttendanceCutoff': PlayerAttendanceCutoff,
  },
  data() {
    return {
      base: null,
      arr_factor: [false,],
      date: '',
      match: {},
      arr_match_event: [],
      arr_member1_player: [],
      arr_member2_player: [],
			arr_group_member: [],
      competition_data: {},
      user: {},
      selected_event: {},
      type: 'player_attendance',
      rnd_time: momentTZ(),
			isFinished: false,
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    match_data(val) {
      this.onForceReload()
    },
    user(val) {
      this.onForceReload()
    },
    user_data(val) {
      if (val == null || val.type == null || (val.type != null && val.type.name != 'coordinator'))
        this.type = 'match_event_user'
    },
  },
  async created() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true,]

    var user = await window.localStorage.getItem('user')
    this.user = JSON.parse(user)
  },
  methods: {
    async onAddEventClicked() {
      this.selected_event = {}
      window.$('#addMatchEventModal').modal('show')
    },
    onPlayerAttendanceClicked() {
      this.type = 'player_attendance'
    },
    onMatchEventClicked() {
      this.type = 'match_event'
    },
    onBackClicked() {
      // if(this.type == 'player_attendance')
      //   this.type = 'match_event'
      // else
      this.$emit('onBackClicked', this.isFinished)
			this.isFinished = false
    },
		onMatchFinished(isFinished){
			this.isFinished = isFinished
		},
    async onForceReload() {
      if (this.match_data.id != null) {
        window.$('#please_wait_modal').modal('show')

				await this.get_match_data()
        await this.get_data()
        this.rnd_time = momentTZ()

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 100)
      }
    },
    onEventClicked(index) {
      if (this.user.type.name == 'coordinator' && this.match_data.status != 'finished') {
        this.selected_event = this.arr_match_event[index]
        window.$('#addMatchEventModal').modal('show')
      }
    },
		onEventCutoffClicked(registration) {
			if (this.user.type.name == 'coordinator' && registration.match_event != null) {
				this.selected_event = registration.match_event
				window.$('#addMatchEventModal').modal('show')
			}
		},
    async onSetFinishClicked() {
			if(
				(this.match.group_member1_disqualified == 1 && this.match.group_member2_disqualified == 1) ||
				(!this.match.is_registration_event1_attend && !this.match.is_registration_event2_attend)
			){
				if(confirm('Some player has been disqualified or not attend match. Are you sure to continue set finish this match?')){
					window.$('#please_wait_modal').modal('show')
					var data = {
						id: this.match.id,
					}

					var response = await this.base.request(this.base.url_api + "/match/best-player", 'post', data)

					setTimeout(() => {
						window.$('#please_wait_modal').modal('hide')
					}, 500)
					if (response != null) {
						if (response.status === "success") {
							this.onForceReload()
						}
						else
							this.base.show_error(response.message)
					}
					else
						console.log(this.$t('server_error'))
				}
			}
			else
				window.$('#addBestPlayerModal').modal('show')
    },
    async onRemoveEventClicked(index) {
      if (confirm('Are you sure?')) {
        var selected_event = this.arr_match_event[index]

        window.$('#please_wait_modal').modal('show')
        var response = await this.base.request(this.base.url_api + "/match/event/delete?id=" + selected_event.id)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
            this.onForceReload()
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    },
		async setDisqualified(type) {
			if (confirm('Are you sure?')) {
				window.$('#please_wait_modal').modal('show')
				var response = await this.base.request(this.base.url_api + "/match/disqualified", 'post', {
					id: this.match.id,
					type: type,
				})
				setTimeout(() => {
					window.$('#please_wait_modal').modal('hide')
				}, 500)

				if (response != null) {
					if (response.status === "success") {
						this.onForceReload()
					}
					else
						this.base.show_error(response.message)
				}
				else
					console.log(this.$t('server_error'))
			}
		},
    async get_data() {
      var date = this.date != '' && this.date != null ? momentTZ(this.date, 'DD/MM/YYYY') : ''
      var response = await this.base.request(this.base.url_api + '/match/event/all?match_id=' + this.match_data.id)

      if (response != null) {
        if (response.status == "success") {
          for (let event of response.data) {
						if(
							(this.match_data.cutoff_group != null && this.match_data.cutoff_group.event_category_sport.cutoff_scoring_type == 'timer') ||
							(this.match_data.tournament != null && this.match_data.tournament.event_category_sport.cutoff_scoring_type == 'timer')
						)
							event.timer_moment = momentTZ.duration(event.total_score, 's')

            if (event.registration_event_player != null)
              event.registration_event_player.url_image = event.registration_event_player.file_name != null ? this.base.host + '/media/registration/player?file_name=' + event.registration_event_player.file_name : NoImage
          }


          this.arr_match_event = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async get_match_data() {
      var response = await this.base.request(this.base.url_api + '/match/all?rel_type=simple&id=' + this.match_data.id)

      if (response != null) {
        if (response.status == "success") {
          response.data.date = momentTZ.tz(response.data.date, this.base.locale_timezone)
          if (response.data.member1 != null && response.data.member2 != null) {
            response.data.member1.registration_event.url_image = response.data.member1.registration_event.url_image != null ? response.data.member1.registration_event.url_image : NoImage
            response.data.member2.registration_event.url_image = response.data.member2.registration_event.url_image != null ? response.data.member2.registration_event.url_image : NoImage
            this.arr_member1_player = response.data.member1.registration_event.player
            this.arr_member2_player = response.data.member2.registration_event.player
						response.data.registration_event1 = response.data.member1.registration_event
						response.data.registration_event2 = response.data.member2.registration_event
          }
					// else if (response.data.cutoff_member1 != null && response.data.cutoff_member2 != null) {
					// 	response.data.cutoff_member1.registration_event.url_image = response.data.cutoff_member1.registration_event.url_image != null ? response.data.cutoff_member1.registration_event.url_image : NoImage
					// 	response.data.cutoff_member2.registration_event.url_image = response.data.cutoff_member2.registration_event.url_image != null ? response.data.cutoff_member2.registration_event.url_image : NoImage
					// 	this.arr_member1_player = response.data.cutoff_member1.registration_event.player
					// 	this.arr_member2_player = response.data.cutoff_member2.registration_event.player
					// 	response.data.registration_event1 = response.data.cutoff_member1.registration_event
					// 	response.data.registration_event2 = response.data.cutoff_member2.registration_event
					// }
          else if (response.data.tournament != null) {
            if(response.data.tournament.registration_event1 != null){
              response.data.tournament.registration_event1.url_image = response.data.tournament.registration_event1.url_image != null ? response.data.tournament.registration_event1.url_image : NoImage
              response.data.tournament.registration_event2.url_image = response.data.tournament.registration_event2.url_image != null ? response.data.tournament.registration_event2.url_image : NoImage
              this.arr_member1_player = response.data.tournament.registration_event1.player
              this.arr_member2_player = response.data.tournament.registration_event2.player
							response.data.registration_event1 = response.data.tournament.registration_event1
							response.data.registration_event2 = response.data.tournament.registration_event2
            }

						if(response.data.tournament.event_category_sport.scoring_type.data == 'cutoff_tournament'){
							var arr = []
							arr.push(response.data.registration_event1)
							arr.push(response.data.registration_event2)
							this.arr_group_member = arr
						}
          }
					var event_category_sport_category = response.data.group != null ? response.data.group.event_category_sport_category : response.data.tournament != null ? response.data.tournament.event_category_sport_category : response.data.cutoff_group.event_category_sport_category
					response.data.event_category_sport_category = event_category_sport_category
					if(event_category_sport_category.event_category_sport.cutoff_scoring_type == 'timer'){
						response.data.group_member1_score_timer = momentTZ.duration(response.data.group_member1_score, 's')
						response.data.group_member2_score_timer = momentTZ.duration(response.data.group_member2_score, 's')
					}


          var allow_edit = false
          var str_coordinator = ''
          var arr_coordinator = response.data.group != null ? response.data.group.event_category_sport.coordinator : response.data.tournament != null ? response.data.tournament.event_category_sport.coordinator : response.data.cutoff_group.event_category_sport.coordinator
          for (let x in arr_coordinator) {
            var coordinator = arr_coordinator[x]

            str_coordinator += coordinator.name
            if (x < arr_coordinator.length - 1)
              str_coordinator += ', '
            if (coordinator.coordinator != null) {

              if (this.user != null && coordinator.coordinator.id == this.user.id) {
                allow_edit = true
              }
            }
          }
          response.data.allow_edit = allow_edit
          response.data.str_coordinator = str_coordinator

          this.match = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court {
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

.match-grid-1 {
  grid-area: 1 / 1 / 2 / 2;
}

.match-grid-2 {
  grid-area: 1 / 2 / 2 / 3;
}

.match-grid-3 {
  grid-area: 1 / 3 / 2 / 4;
}

.match-grid-4 {
  grid-area: 2 / 1 / 3 / 2;
  z-index: 2;
  margin: 1rem 0;
}

.match-grid-5 {
  grid-area: 2 / 2 / 3 / 3;
  z-index: 2;
  margin: 1rem 0;
}

.match-grid-6 {
  grid-area: 2 / 3 / 3 / 4;
  z-index: 2;
  margin: 1rem 0;
}

.match-grid-7 {
  grid-area: 2 / 1 / 3 / 4;
  z-index: 1;
  position: relative;

  &:after {
    content: "";
    /* width: 100vw; */
    height: 100%;
    background-color: var(--primary);
    position: absolute;
    /* margin: 0 -100vw; */
    left: -50vw;
    // right: 50vw;
    padding: 0 100vw;
    // margin-left: -50vw;
  }
}

// .match-grid-7{
//   grid-area: 2 / 1 / 3 / 4;

// }

// .form-control{
//   background-position: right .75rem center;
// }</style>
