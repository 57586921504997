<template>
  <div class="modal fade" id="addMatchEventModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ event_data.id != null ? 'Edit' : 'Add' }} Match Event</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="m-0">Type</label>
            <select v-model="selected_type" :disabled="event_data.id != null" class="form-control">
              <option value="">Choose Type</option>
              <option :value="type.id" v-for="(type, index) in arr_type" :key="index"
								:disabled="cutoff_scoring_type == 'timer' && (type.id == 'yellow_card' || type.id == 'red_card')">{{ type.name }}</option>
            </select>
          </div>

          <div class="form-group" v-show="selected_type != 'match_finished'">
            <label class="m-0">Group Member</label>
            <select v-model="selected_group_member" :disabled="event_data.id != null" class="form-control">
              <option value="">Choose Group Member</option>
              <option :value="group_member.id" v-for="(group_member, index) in arr_group_member" :key="index">{{ group_member.team_name }}</option>
            </select>
          </div>

          <div class="form-group" v-show="selected_type != 'match_finished' && match_data.cutoff_group == null">
            <label class="m-0">Player</label>
            <select v-model="selected_registration_event_player" :disabled="event_data.id != null" class="form-control">
              <option value="">Choose Player</option>
              <option :value="registration_event_player.id" v-for="(registration_event_player, index) in arr_registration_event_player" :key="index">{{ registration_event_player.name }}</option>
            </select>
          </div>

          <div class="form-group" v-show="selected_type == 'score' && cutoff_scoring_type == 'score'">
            <label class="m-0">Total Score</label>
            <input type="text" v-model="total_score" class="form-control">
          </div>

					<div class="form-group" v-show="selected_type == 'score' && cutoff_scoring_type == 'timer'">
						<label class="m-0">Total Time</label>
						<div class="d-flex align-items-center">
							<input type="text" v-model="time_hour" class="form-control">
							<p class="m-0 mx-1">:</p>
							<input type="text" v-model="time_minute" class="form-control">
							<p class="m-0 mx-1">:</p>
							<input type="text" v-model="time_second" class="form-control">
						</div>
					</div>

          <div class="form-group" v-show="selected_type != 'match_finished' && cutoff_scoring_type == 'score'">
            <label class="m-0">Minute Time</label>
            <input type="text" v-model="minute_time" class="form-control">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="submit">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

import NoImage from '@/assets/no_image.png'

export default {
  props: ['match_data', 'event_data', ],
  components: {
  },
  data() {
    return {
      base: null,
      arr_factor: [false, ],
      selected_group_member: '',
      selected_registration_event_player: '',
      selected_type: '',
      minute_time: '0',
      total_score: '0',
			time_hour: '0',
			time_minute: '0',
			time_second: '0',
      arr_group_member: [],
      arr_registration_event_player: [],
      arr_type: [
        {
          id: 'score',
          name: 'Score',
        },
        {
          id: 'yellow_card',
          name: 'Yellow Card',
        },
        {
          id: 'red_card',
          name: 'Red Card',
        },
        // {
        //   id: 'match_finished',
        //   name: 'Match Finished',
        // },
      ],
			cutoff_scoring_type: 'score',
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      // this.manage_start_animation()
    },
    minute_time(val){
      this.minute_time = this.base.to_currency_format(val, 120)
    },
    total_score(val){
      // var num = this.base.str_to_double(val)
      this.total_score = this.base.to_currency_format(val)
    },
		time_hour(val){
			// var num = this.base.str_to_double(val)
			this.time_hour = this.base.to_currency_format(val)
		},
		time_minute(val){
			// var num = this.base.str_to_double(val)
			this.time_minute = this.base.to_currency_format(val, 59)
		},
		time_second(val){
			// var num = this.base.str_to_double(val)
			this.time_second = this.base.to_currency_format(val, 59)
		},
    selected_group_member(val){
      var selected_group_member = {}
      for(let group_member of this.arr_group_member){
        if(val == group_member.id){
          selected_group_member = group_member
          break
        }
      }
      this.get_player_data(selected_group_member.registration_event != null ? selected_group_member.registration_event : selected_group_member)
    },
    match_data(val){

      var arr_group_member = []
      if(val.member1 != null && val.member2 != null){
        val.member1.team_name = val.member1.registration_event.team_name
        val.member2.team_name = val.member2.registration_event.team_name

        arr_group_member.push(val.member1)
        arr_group_member.push(val.member2)
      }
      else if(val.tournament != null){
        arr_group_member.push(val.tournament.registration_event1)
        arr_group_member.push(val.tournament.registration_event2)
      }
			else if(val.cutoff_group != null){
				for(let member of val.cutoff_group.member)
					arr_group_member.push(member.registration_event)
			}
      this.arr_group_member = arr_group_member


			if(val.id != null){

				if(val.cutoff_group != null)
					this.cutoff_scoring_type = val.cutoff_group.event_category_sport.cutoff_scoring_type
				else if(val.tournament != null)
					this.cutoff_scoring_type = val.tournament.event_category_sport.cutoff_scoring_type
			}
    },
		cutoff_scoring_type(val){
			var arr_type = JSON.parse(JSON.stringify(this.arr_type))
			for(let type of arr_type){
				if(type.id == 'score')
					type.name = val == 'timer' ? 'Timer' : 'Score'
			}
			this.arr_type = arr_type
		},
    event_data(val){
      this.selected_group_member = val.group_member != null ? val.group_member.id : val.registration_event != null ? val.registration_event.id : ''
      this.selected_registration_event_player = val.registration_event_player != null ? val.registration_event_player.id : ''
      this.selected_type = val.type != null ? val.type : ''
      this.minute_time = val.minute_time != null ? val.minute_time.toLocaleString(this.base.locale_string) : ''
			if(this.cutoff_scoring_type == 'score')
				this.total_score = val.total_score != null ? val.total_score.toLocaleString(this.base.locale_string) : ''
			else if(this.cutoff_scoring_type == 'timer'){
				var total_timer = momentTZ.duration(val.total_score, 's')
				this.time_hour = total_timer.hours().toLocaleString(this.base.locale_string)
				this.time_minute = total_timer.minutes().toLocaleString(this.base.locale_string)
				this.time_second = total_timer.seconds().toLocaleString(this.base.locale_string)
			}
    },
  },
  async created() {
    this.base = new Base()
    // window.addEventListener('scroll', this.handleScroll)
    this.arr_factor = [true,]
  },
  methods: {
    async get_player_data(registration_event) {
      var date = this.date != '' && this.date != null ? momentTZ(this.date, 'DD/MM/YYYY') : ''
      var response = await this.base.request(this.base.url_api + '/event/registration/player/all?registration_event_id=' + registration_event.id)

      if (response != null) {
        if (response.status == "success") {
          for(let player of response.data){
            player.url_image = player.file_name != null ? this.base.host + '/media/user?file_name=' + player.file_name : NoImage
          }

          this.arr_registration_event_player = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
    async submit(){
      if(this.selected_type != 'match_finished' && this.selected_group_member == '')
        this.base.show_error('Group Member is not Selected')
      else if(this.selected_type != 'match_finished' && this.selected_registration_event_player == '' && this.match_data.cutoff_group == null)
        this.base.show_error('Player is not Selected')
      else if(this.selected_type == '')
        this.base.show_error('Type is not Selected')
      else if(this.selected_type != 'match_finished' && this.minute_time == '0' && this.cutoff_scoring_type == 'score')
        this.base.show_error('Minute Time is Empty')
      else{
        window.$('#please_wait_modal').modal('show')
				if(this.cutoff_scoring_type == 'timer'){
					var total_timer = momentTZ.duration({
						hours: this.time_hour,
						minutes: this.time_minute,
						seconds: this.time_second,
					})
				}

        var data = {
          match_id: this.match_data.id,
          type: this.selected_type,
          minute_time: this.base.str_to_double(this.minute_time),
          total_score: this.cutoff_scoring_type == 'timer' ? total_timer.as('seconds') : this.base.str_to_double(this.total_score),
        }

				if(this.match_data.cutoff_group != null)
					data.registration_event_id = this.selected_group_member
				else{
					data.group_member_id = this.selected_group_member
					data.registration_event_player_id = this.selected_registration_event_player
				}

        if(this.event_data.id != null)
          data.id = this.event_data.id

        var response = await this.base.request(this.base.url_api + "/match/event" + (this.event_data.id != null ? '/edit' : ''), 'post', data)

        setTimeout(() => {
          window.$('#please_wait_modal').modal('hide')
        }, 500)
        if (response != null) {
          if (response.status === "success") {
						this.total_score = '0'
						this.minute_time = '0'
						this.selected_group_member = ''
						this.selected_registration_event_player = ''

            window.$('#addMatchEventModal').modal('hide')
            this.$emit('onForceReload')
          }
          else
            this.base.show_error(response.message)
        }
        else
          console.log(this.$t('server_error'))
      }
    }
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court{
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>
